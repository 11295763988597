<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3 {{type}} System User {{type === 'Edit' ? ' - #' + form.id : ''}}
			el-form
				el-row.mt-2
					el-col(:span="24")
						h3.text-left Details
				el-row(:gutter="24")

					// Username
					el-col(:xs="24", :sm="8")
						el-form-item(label="Username" :error="errors.username.s ? errors.username.m : ''" v-bind:class="{'is-invalid': errors.username.s}")
							el-input(v-model="form.username", placeholder="Username", v-debounce:300ms="checkUsernameExists")

					// First Name
					el-col(:xs="24", :sm="8")
						el-form-item(label="First Name" :error="errors.first_name.s ? errors.first_name.m : ''" v-bind:class="{'is-invalid': errors.first_name.s}")
							el-input(v-model="form.first_name" placeholder="First Name")

					// First Name
					el-col(:xs="24", :sm="8")
						el-form-item(label="Last Name" :error="errors.last_name.s ? errors.last_name.m : ''" v-bind:class="{'is-invalid': errors.last_name.s}")
							el-input(v-model="form.last_name" placeholder="Last Name")


					el-col(:xs="24", :sm="8")
						el-form-item(label="E-Mail Address" :error="errors.email.s ? errors.email.m : ''" v-bind:class="{'is-invalid': errors.email.s}")
							el-input(v-model="form.email" placeholder="E-Mail address" v-debounce:300ms="checkEmailExists")

					el-col(:xs="24", :sm="8")
						el-form-item(label="Password" :error="errors.password.s ? errors.password.m : ''" v-bind:class="{'is-invalid': errors.password.s}")
							el-input(v-model="form.password" placeholder="Password (at least 8 characters)" type="password")
							small.text-danger.font-italic.text-left * Leave blank if no change needed
					el-col(:xs="24", :sm="8")
						el-form-item(label="Role" :error="errors.role.s ? errors.role.m : ''" v-bind:class="{'is-invalid': errors.role.s}" :style="{'zIndex':1000}")
							template(slot="label")
								//label( ) Role&nbsp;
									//el-popover(
										placement="top-start"
										width="480"
										title="User Types"
										trigger="hover"
									//)
										hr
										label.d-block
											p
												b ADMIN:&nbsp;
												span Highest Privileges - Role cannot be changed, can commit CRUD operations on users, can see leads and delete leads, can create, update, <sup*</sup>delete and view Integrations. Can view dashboard.
										label.d-block
											p
												b MODERATOR:&nbsp;
												span Can commit CRUD operations on users, can see leads and delete leads, can create, update, and view Integrations. Can view dashboard.
										label.d-block
											p
												b MANAGER:&nbsp;
												span Can view dashboard and leads.
											//Affiliate can view only it's list ()
										label.d-block
											p
												b GUEST:&nbsp;
												span Can view dashboard only.

										hr
										small.text-danger
											i *CRUD - Create, Read, Update, Delete
										i.el-icon-question.chooseable.no-deco(slot="reference",)
							el-select.w-100(filterable="", v-model="form.role" @change="roleChanged")
								el-option(value="" label="- Select Role -" )
								el-option(v-for="o of roles_options" :value="o.v" :label="o.t")

				el-divider

				el-row.mt-2(:gutter="24")
					el-col(:span="24")
						h3.text-left Telegram Notifier
						p.text-left In this section you can add or remove a Telegram username. The username must be valid, also take into account that it is case sensitive, otherwise the user won't be able to use the bot due to security mesures - the bot checks and compares the username and the one in the database. In case of failure it blocks the user.
						p.text-left You can control whether the user gets notifications or not, and whether the user is subscribed to the bot or not.
						p.text-left.text-danger
							i Note: A blank username counts as if it was never set.
					el-col(:xs="24", :sm="8")
						el-form-item(label="Telegram Username" :error="errors.first_name.s ? errors.first_name.m : ''" v-bind:class="{'is-invalid': errors.first_name.s}")
							el-input(v-model="form.telegram_bot_id" placeholder="Username")
								el-button(slot="append" icon="el-icon-delete" v-b-tooltip.html.hover-right="", title="Clear Username" @click="() => this.form.telegram_bot_id = ''")
					el-col(:xs="24", :sm="3")
						el-form-item(label="Notifications Enabled" label-position="top")
							el-switch.w-100(v-model="form.telegram_notification_enabled" active-text="On" inactive-text="Off")

				el-divider

				el-row.mt-2
					el-col(:span="24")
						h3.text-left Permissions

				el-row(:gutter="24")
					el-col(:xs="24", :sm="8")
						el-form-item(label="Funnel Families" label-position="top" label-width="auto")
							el-select.w-100(v-if="funnel_families_options" filterable="", multiple="",	 v-model='form.allowed_funnel_families', placeholder='By Funnel Family' @change="selectRelatedFunnelsBrands" :collapse-tags="true")
								el-option.text-bold(label='All', value='all', :disabled="form.allowed_funnel_families.length > 0 && form.allowed_funnel_families[0] !== 'all'")
								el-option(v-for="o of funnel_families_options", :label="o.t", :value="o.v" :disabled="form.allowed_funnel_families[0] === 'all'")

					el-col(:xs="24", :sm="8")
						el-form-item(label="Traffic Sources" label-position="top" label-width="auto")
							el-select.w-100(v-if="traffic_source_options" filterable="", multiple="",	 v-model='form.allowed_traffic_sources', placeholder='By Traffic Sources' :collapse-tags="true")
								el-option.text-bold(label='All', value='all', :disabled="form.allowed_traffic_sources.length > 0 && form.allowed_traffic_sources[0] !== 'all'")
								el-option(v-for="o of traffic_source_options", :label="o.t + ` - ${o.n}`", :value="o.v" :disabled="form.allowed_traffic_sources[0] === 'all'")

					el-col(:xs="24", :sm="8")
						el-form-item(label="Allowed Columns" label-position="top" label-width="auto")
							el-select.w-100(v-if="allowed_columns" filterable="", multiple="",	 v-model='form.allowed_columns_affiliate', placeholder='By Allowed Columns' :collapse-tags="true")
								el-option.text-bold(label='All', value='all', :disabled="form.allowed_columns_affiliate.length > 0 && form.allowed_columns_affiliate[0] !== 'all'")
								el-option(v-for="o of allowed_columns", :label="o.t", :value="o.v" :disabled="form.allowed_columns_affiliate[0] === 'all'")

					el-col(:xs="24", :sm="8")
						el-form-item(label="Customer View Types" label-position="top" label-width="auto")
							el-select.w-100(filterable="", multiple="",	 v-model='form.customer_view_types', placeholder='Lead, Customer, FTD etc.' :collapse-tags="true")
								el-option(v-for="o of customer_view_types_options", :label="o.t", :value="o.v")

					el-col(:xs="24", :sm="8")
						el-form-item(label="Source Origin View Types" label-position="top" label-width="auto")
							el-select.w-100(filterable="", multiple="",	 v-model='form.source_origin_view_types', placeholder='API, IFRAME, GOOGLE SHEETS etc.' :collapse-tags="true")
								el-option(v-for="o of source_origin_view_types_options", :label="o.t", :value="o.v")

				el-row(:gutter="24")
					el-col(:xs="24", :sm="8")
						el-form-item(label="Brand" label-position="top" label-width="auto")
							el-select.w-100(v-if="brand_options" filterable="", multiple="",	 v-model='form.allowed_brands', placeholder='By Brand' :collapse-tags="true")
								el-option.text-bold(label='All', value='all', :disabled="form.allowed_brands.length > 0 && form.allowed_brands[0] !== 'all'")
								el-option(v-for="o of brand_options", :label="o.t", :value="o.v" :disabled="form.allowed_brands[0] === 'all'")

					el-col(:xs="24", :sm="8")
						el-form-item(label="Integration" label-position="top" label-width="auto" )
							el-select.w-100(v-if="integration_options" filterable="",	multiple="",	 v-model='form.allowed_integrations', placeholder='By Integration', :disabled="form.allowed_brands.length === 0" :collapse-tags="true")
								el-option.text-bold(label='All', value='all' :disabled="form.allowed_integrations.length > 0 && form.allowed_integrations[0] !== 'all'")
								el-option(v-for="o of integration_options", :label="`${o.t} ${o.n ? `(${o.n})` : ''}`", :value="o.v", v-if="form.allowed_brands[0] !== 'all' && form.allowed_brands.includes(o.b)", :disabled="form.allowed_integrations[0] === 'all'")
								el-option(v-for="o of integration_options", :label="`${o.t} ${o.n ? `(${o.n})` : ''}`", :value="o.v", v-if="form.allowed_brands[0] === 'all'", :disabled="form.allowed_integrations[0] === 'all'")

					el-col(:xs='24' :sm='8')
						el-form-item(label-position="top" label-width="auto" )
							template(slot="label")
								span Associated Affiliates&nbsp;
									small.text-success (The affiliate will be added to the list in any case)
							el-select.w-100(v-if="affiliates_options" filterable="" multiple="" placeholder="Associated Affiliates" v-model="form.associated_affiliates")
								el-option.text-bold(label="All" value="all" :disabled="form.associated_affiliates.length > 0 && form.associated_affiliates[0] !== 'all'")
								el-option(v-for="o of affiliates_options", :label="o.t", :value="o.v" :disabled="form.associated_affiliates[0] === 'all'")


					el-col(:xs="24", :sm="3")
						el-form-item(label="Is User Active" label-position="top")
							el-switch.w-100(v-model="form.in_use" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3")
						el-form-item(label="Can Manage Users" label-position="top")
							el-switch.w-100(v-model="form.can_manage_users" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(1)")
						el-form-item(label="Can View Logs" label-position="top")
							el-switch.w-100(v-model="form.can_view_logs" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(2)")
						el-form-item(label="Can View Deleted" label-position="top")
							el-switch.w-100(v-model="form.can_view_deleted" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(4)")
						el-form-item(label="Can Toggle Fields" label-position="top")
							el-switch.w-100(v-model="form.can_toggle_fields" active-text="On" inactive-text="Off")


					el-col(:xs="24", :sm="3" v-if="minimumRole(4)")
						el-form-item(label="Can Toggle FTD" label-position="top")
							el-switch.w-100(v-model="form.can_toggle_ftd" active-text="On" inactive-text="Off")


					el-col(:xs="24", :sm="3" v-if="minimumRole(4)")
						el-form-item(label="Can Download Excel File" label-position="top")
							el-switch.w-100(v-model="form.can_download_excel" active-text="On" inactive-text="Off")


					el-col(:xs="24", :sm="3" v-if="minimumRole(4)")
						el-form-item(label="Can View/Write Comments" label-position="top")
							el-switch.w-100(v-model="form.can_click_show_comments" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(4)")
						el-form-item(label="Can View Meta" label-position="top")
							el-switch.w-100(v-model="form.can_click_meta" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3")
						el-form-item(label="Can Use API" label-position="top")
							el-switch.w-100(v-model="form.can_use_api" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(1)")
						el-form-item(label="Can Copy Leads" label-position="top")
							el-switch.w-100(v-model="form.can_copy_leads" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(2)")
						el-form-item(label="Can Reassign Leads" label-position="top")
							el-switch.w-100(v-model="form.can_reassign_leads" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(1)")
						el-form-item(label="Can edit Traffic Source" label-position="top")
							el-switch.w-100(v-model="form.can_edit_traffic_source" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(1)")
						el-form-item(label="Can edit Traffic Source" label-position="top")
							el-switch.w-100(v-model="form.can_edit_details" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(1)")
						el-form-item(label="Can View/Edit Settings" label-position="top")
							el-switch.w-100(v-model="form.can_view_settings" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(1)")
						el-form-item(label="Can Encrypt/Decrypt " label-position="top")
							el-switch.w-100(v-model="form.can_encrypt_decrypt" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(3)")
						el-form-item(label="Can See Brand Owner " label-position="top")
							el-switch.w-100(v-model="form.can_see_brand_owner" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(3)")
						el-form-item(label="Can See Affiliate (params) " label-position="top")
							el-switch.w-100(v-model="form.can_see_param_affiliate" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="minimumRole(1)")
						el-form-item(label="Can Alter Settings " label-position="top")
							el-switch.w-100(v-model="form.can_change_system_variables" active-text="On" inactive-text="Off")


				el-row.mt-2(v-if="form.role === '4'")
					el-col(:span="24")
						h3.text-left Affiliate
				el-row(:gutter="24" v-if="form.role === '4'")
					el-col(:xs="24", :sm="12" :md="8")
						el-form-item(label="API Key" label-position="top" :error="errors.api_token.s ? errors.api_token.m : ''" v-bind:class="{'is-invalid': errors.api_token.s}")
							el-input(:readonly="true" :value="form.api_token" placeholder="Empty for security reasons")
								el-button(slot="append" icon="el-icon-refresh" v-b-tooltip.html.hover.top="", title="Click to set new token" @click="updateApiKey")
							small.text-danger.font-italic.text-left * Leave blank if no change needed

					el-col(:xs="24", :sm="12" :md="8"	v-if="form.role === '4'")
						el-form-item(label="Permitted Origins (IPv4)" label-position="top" :error="errors.allowed_ips.s ? errors.allowed_ips.m : ''" v-bind:class="{'is-invalid': errors.allowed_ips.s}")
							el-select.w-100(v-model="form.allowed_ips" multiple="" filterable="" allow-create="" default-first-option="" placeholder="E.g 127.0.0.1" @change="ipAdded")
								el-option(key="0.0.0.0" label="0.0.0.0" value="0.0.0.0") All
								el-option(:key="defaultIP" :label="defaultIP" :value="defaultIP")

					el-col(:xs="24", :sm="8")
						el-form-item(label="Allowed Campaigns" label-position="top" label-width="auto")
							el-select.w-100( filterable="", multiple="",	 v-model='form.allowed_campaigns', placeholder='By Campaigns')
								el-option.text-bold(label='All', value='all', :disabled="form.allowed_campaigns.length > 0 && form.allowed_campaigns[0] !== 'all'")
								el-option(v-for="o of campaigns_options", :label="o.t", :value="o.v" :disabled="form.allowed_campaigns[0] === 'all'")
				
				el-row.text-left(:gutter="24" v-if="form.role === '4'")
					el-col(:xs="24", :sm="3")
						el-form-item(label="Affiliate linked to " label-position="top")
							el-switch.w-100(v-model="form.affiliate_create_ts_relation" active-text="On" inactive-text="Off")


					el-col(:xs="24", :sm="3")
						el-form-item(label="Affiliate Can See Errors " label-position="top")
							el-switch.w-100(v-model="form.affiliate_can_see_error" active-text="On" inactive-text="Off")


					el-col(:xs="24", :sm="3")
						el-form-item(label="Affiliate Gets Statuses" label-position="top")
							el-switch.w-100(v-model="form.affiliate_fetch_status" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3")
						el-form-item(label="Affiliate Gets Redirect Link" label-position="top")
							el-switch.w-100(v-model="form.pass_redirect_link" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="form.affiliate_fetch_ftd")
						el-form-item(label="Prefer Param X" label-position="top")
							el-switch.w-100(v-model="form.prefer_param_x" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3"  v-if="form.role === '4'")
						el-form-item(label="Show Only Own" label-position="top")
							el-switch.w-100(v-model="form.show_only_own" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="3" v-if="form.affiliate_fetch_status")
						el-form-item(label="Prefer Param Y" label-position="top")

							el-radio-group.w-100.text-left(v-model="form.prefer_param_y" :fill="form.prefer_param_y === true ? '#409EFF' : 'red'")
								el-radio(v-for="o of affiliate_status_param_y_options" :label="o.v") {{ o.t }}

				el-divider

				el-row.text-left.mt-3
					h3 Limits
					p In this section you can set limits for the user. The limits are used to control the amount of data the user can export or see in the main table (Search Tab). The limits are set in days, and the user can export data only for the last X days.
				el-row(:gutter="24")
					el-col(:xs="24", :sm="12" :md="6" :xl="3")
						el-form-item(label="Minimal Fetch/Export Status" label-position="top")
							el-switch.w-100(v-model="form.allowed_minimal_date_created_flag" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="12" :md="6" :xl="3" v-if="form.allowed_minimal_date_created_flag")
						el-form-item(label="Offset in days" label-position="top")
							el-input-number.w-100(v-model="form.allowed_minimal_date_created_export_interval" :min="0" :max="1800" :step="1" controls-position="right" placeholder="0")
					el-col(:xs="24", :sm="12" :md="6" :xl="3" v-if="form.allowed_minimal_date_created_flag")
						el-form-item(label-position="top")
							template(slot="label")
								span Calculated Offset&nbsp;
									small.text-success (Read-Only)
							el-date-picker(:value="calculatedOffset" readonly="")

				el-row(:gutter="12" justify="between")
					el-button(@click="submit" type="primary") Save
					el-button(type="danger" @click="$router.push({name: 'user-list'})") Cancel



</template>


<style lang="scss">
 .el-input-group__prepend {
	background-color: #fff;
}
</style>


<script>
	import Vue from 'vue';
	import validator from 'validator';
	import {v4} from "uuid";
	import publicIp from 'public-ip';
	import moment from "moment";

	function clearUnusedProps(body) {
		const data = {...body};
		delete data.short_uuid;
		delete data.role_name;
		delete data.partial_api_token;
		if(body.role !== '4') {
			if(!body.api_token || body.api_token === '') delete data.api_token;
			if(!body.allowed_ips || body.allowed_ips.length === 0) delete data.allowed_ips;
		}
		return data;
	}

	const $form = {
		username: '',
		first_name: '',
		last_name: '',
		role: '',
		role_name: '',
		email: '',
		in_use: true,
		password: '',
		telegram_bot_id: '',
		telegram_notification_subscribe: false,
		telegram_notification_enabled: false,

		can_click_meta: false,
		can_click_show_comments: false,
		can_download_excel: false,
		can_toggle_ftd: false,

		can_manage_users: false,
		can_view_logs: false,
		can_view_deleted: false,
		can_copy_leads: false,
		can_reassign_leads: false,
		can_edit_traffic_source: false,
		can_edit_details: false,
		can_toggle_fields: false,
		can_view_settings: false,
    can_encrypt_decrypt: false,
    can_see_brand_owner: false,
		can_see_param_affiliate: false,
		can_change_system_variables: false,

		affiliate_create_ts_relation: false,
		affiliate_can_see_error: false,
		pass_redirect_link: false,
		allowed_brands: [],
		allowed_integrations: [],
		allowed_funnel_families: [],
		allowed_campaigns: [],
		api_token: '',
		allowed_ips: [],
		can_use_api: false,
		allowed_traffic_sources: [],
		allowed_columns_affiliate: [],
		associated_affiliates: [],
		affiliate_fetch_ftd: true,
		affiliate_fetch_status: true,
		prefer_param_x: false,
		prefer_param_y: "OFF",
		show_only_own: false,
		customer_view_types: ["lead","customer","customer_failed","ftd","noftd"],
		source_origin_view_types: ["IFRAME", "FLOW","API","GOOGLE_SHEETS"],

		allowed_minimal_date_created_flag: false,
		allowed_minimal_date_created_export_interval: 0
	};

	const $errors = {
		username: {s: false, m: ''},
		first_name: {s: false, m: ''},
		last_name: {s: false, m: ''},
		role: {s: false, m: ''},
		email: {s: false, m: ''},
		in_use: {s: false, m: ''},
		password: {s: false, m: ''},
		api_token: {s: false, m: ''},
		allowed_ips: {s: false, m: ''},
	}

	export default {
		async beforeRouteEnter(to, from, next) {
			if (to.name !== 'user-create' && to.name !== 'user-edit') return next('/');

			if (to.name === 'user-edit') {
				let entity = await Vue.apix.sendHttpRequest('GET','user/' + to.params.id)
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {opt: entity.role, filters: '1e6385c1-23ab-4c97-a666-a0e3fb7d31d2,1e6385c1-23ab-4c97-a666-a0e3fb7d31d2,cf501858-15e4-4bd9-90df-aff9ae761249,eade7b54-f499-4265-925b-12691f7ea2e5,16033d20-1f17-4a5b-aa28-0f502de555c0,0661e754-6a3a-4abe-842d-975a2d41a871,25cf0ae8-346c-49c1-88d5-f2455cf7c815,f9dbf2a3-8d5b-4619-9304-500249688899,7141fd9b-916b-4788-b7a6-b87d19b6438a,95e5744a-9964-42d4-ae26-c3e591ecb7e0,4b669104-0ac0-4df7-a5bb-a86fc0aacbb5,49f06cec-46b0-4237-bf9a-d4d3969e5af6,9e832481-2c11-4021-ba13-0dcc5487e3f7'});
				if(entity.role === '0')	 return next('/');
				next(vm => {

					vm.allowed_columns = options.allowed_columns.sort((a,b)=> (a.t > b.t ? 1 : -1));
					vm.funnel_families_relations = options.funnel_families_relations;
					vm.affiliate_status_param_y_options = options.affiliate_status_param_y;
					vm.roles_options = options.roles;
					vm.brand_options = options.brand;
					vm.affiliates_options = options.affiliates;
					vm.integration_options = options.integration_extra;
					vm.funnel_families_options = options.funnel_families;
					vm.traffic_source_options = options.traffic_sources;
					vm.campaigns_options = options.campaigns_options;
					vm.customer_view_types_options = options.customer_view_types;
					vm.source_origin_view_types_options = options.source_origin_view_types;
					vm.type = 'Edit'
					vm.form = {...$form, ...entity};
					delete vm.form.uuid;
					vm.form.id = to.params.id
				})
			} else if (to.name === 'user-create') {
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {opt: '0', filters: '1e6385c1-23ab-4c97-a666-a0e3fb7d31d2,1e6385c1-23ab-4c97-a666-a0e3fb7d31d2,cf501858-15e4-4bd9-90df-aff9ae761249,eade7b54-f499-4265-925b-12691f7ea2e5,16033d20-1f17-4a5b-aa28-0f502de555c0,15dfb9c1-b6c6-4714-904e-50d7417eb4be,0661e754-6a3a-4abe-842d-975a2d41a871,25cf0ae8-346c-49c1-88d5-f2455cf7c815,f9dbf2a3-8d5b-4619-9304-500249688899,7141fd9b-916b-4788-b7a6-b87d19b6438a,95e5744a-9964-42d4-ae26-c3e591ecb7e0,4b669104-0ac0-4df7-a5bb-a86fc0aacbb5,49f06cec-46b0-4237-bf9a-d4d3969e5af6,9e832481-2c11-4021-ba13-0dcc5487e3f7'});
				next(vm => {
					vm.default_columns_hash = options.default_columns;
					vm.funnel_families_relations = options.funnel_families_relations;
					vm.allowed_columns = options.allowed_columns.sort((a,b)=> (a.t > b.t ? 1 : -1));
					vm.affiliate_status_param_y_options = options.affiliate_status_param_y;
					vm.roles_options = options.roles;
					vm.brand_options = options.brand;
					vm.affiliates_options = options.affiliates;
					vm.integration_options = options.integration_extra;
					vm.funnel_families_options = options.funnel_families;
					vm.traffic_source_options = options.traffic_sources;
					vm.campaigns_options = options.campaigns_options;
					vm.customer_view_types_options = options.customer_view_types;
					vm.source_origin_view_types_options = options.source_origin_view_types;
					vm.type = 'Create'
					vm.form = JSON.parse(JSON.stringify({...$form, allowed_columns_affiliate: options.default_columns}));

				})
			}

		},
		data() {
			return {
				type: 'Create',
				form: JSON.parse(JSON.stringify($form)),
				errors: JSON.parse(JSON.stringify($errors)),
				affiliate_status_param_y_options: [],
				customer_view_types_options: [],
				source_origin_view_types_options: [],
				allowed_columns: [],
				roles_options: [],
				brand_options: [],
				integration_options: [],
				affiliates_options: [],
				funnel_families_options: [],
				traffic_source_options: [],
				funnel_families_relations: {},
				campaigns_options: [],
				existsError: false,
				defaultIP:	''
			}
		},
		mounted() {
			publicIp.v4().then(ip => this.defaultIP = ip)
		},
		destroyed() {
			this.form = $form;
			this.errors = $errors;
		},
		computed: {
			calculatedOffset() {

				// diff in days from today to form.allowed_minimal_date_created_export_interval
				return moment().subtract(this.form.allowed_minimal_date_created_export_interval, 'days').format('YYYY-MM-DD');

			}
		},
		methods: {
			updateApiKey() {
				this.form.api_token = v4();
			},

			ipAdded(event) {
				if(event && !validator.isIP(event[event.length -1])) this.form.allowed_ips.pop();
			},
			async roleChanged(event) {

				let options = await this.$apix.sendHttpRequest('GET', 'components/options', {opt: event, filters: '15dfb9c1-b6c6-4714-904e-50d7417eb4be,f9dbf2a3-8d5b-4619-9304-500249688899'});
				this.form.allowed_columns_affiliate = options.default_columns
				this.allowed_columns = [];
				this.allowed_columns = options.allowed_columns;

				switch(`${event}`) {
					case '0':
					case '1': {
						this.form.allowed_minimal_date_created_flag = false;
						this.form.allowed_minimal_date_created_export_interval = 0;
						break;
					}
					case '2':
					case '3': {
						this.form.allowed_minimal_date_created_flag = true;
						this.form.allowed_minimal_date_created_export_interval = 180;
						break;
					}
					case '4': {
						this.form.allowed_minimal_date_created_flag = true;
						this.form.allowed_minimal_date_created_export_interval = 90;
						break;
					}
					case '5': {
						this.form.allowed_minimal_date_created_flag = true;
						this.form.allowed_minimal_date_created_export_interval = 1;
						break;
					}
				}
			},
			minimumRole(role) {
				const formRole = this.form.role;
				return formRole !== '' && +formRole <= role;
			},
			exactRole(role) {
				return	+this.form.role === role;
			},
			async submit() {
				if(!this.validate()) return;
				try {
					if(this.type === 'Create') {
						let result = await this.$apix.sendHttpRequest('POST', 'user/', clearUnusedProps(this.form));
						if(result.id) this.$router.push({name: 'user-list'})
					} else if(this.type === 'Edit') {
						let result = await this.$apix.sendHttpRequest('PUT', 'user/' + this.form.id, clearUnusedProps(this.form));
						if(result) this.$router.push({name: 'user-list'})
					}
				} catch(e) {
					console.error(e);
				}
			},
			validate() {
				this.errors = JSON.parse(JSON.stringify($errors));
				let err = false;

				if(this.form.username === '') {
					this.errors.username = { s: true, m: 'username is mandatory' };
					err = true;
				} else if(this.form.username.length < 2) {
					this.errors.username = { s: true, m: 'username is too short, min 2 characters' };
					err = true;
				} else if(!validator.isAlphanumeric(this.form.username)) {
					this.errors.username = { s: true, m: 'username must be alphanumeric' };
					err = true;
				}

				if(this.form.first_name === '') {
					this.errors.first_name = { s: true, m: 'first name is mandatory' };
					err = true;
				} else if(this.form.first_name.length < 2) {
					this.errors.first_name = { s: true, m: 'first name is too short, min 2 characters' };
					err = true;
				} else if(!validator.isAlphanumeric(this.form.first_name)) {
					this.errors.first_name = { s: true, m: 'first name must be alphanumeric' };
					err = true;
				}

				if(this.form.last_name === '') {
					this.errors.last_name = { s: true, m: 'last name is mandatory' };
					err = true;
				} else if(this.form.last_name.length < 2) {
					this.errors.last_name = { s: true, m: 'last name	is too short, min 2 characters' };
					err = true;
				} else if(!validator.isAlphanumeric(this.form.last_name)) {
					this.errors.last_name = { s: true, m: 'last name	must be alphanumeric' };
					err = true;
				}

				if(this.type === 'create') {
					if (this.form.password === '') {
						this.errors.password = {s: true, m: 'password is mandatory'};
						err = true;
					} else if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(this.form.password)) {
						this.errors.password = {s: true, m: 'Password must be at least 8 characters, alpha numeric and symbols'};
						err = true;
					}
				}

				if(this.form.role === '' || !this.form.role) {
					this.errors.role = { s: true, m: 'role is mandatory' };
					err = true;
				}

				if(this.form.api_token === '' && this.form.role === '4' && this.type === 'Create') {
					this.errors.api_token = { s: true, m: 'api key not set' };
					err = true;
				}

				if(this.form.allowed_ips.length === 0 && this.form.role === '4') {
					this.errors.allowed_ips = { s: true, m: 'whitelist of ips is empty' }
					err = true;
				}

				return !err;
			},
			selectRelatedFunnelsBrands() {
				if(this.form.allowed_funnel_families[0] === 'all') return;
				let integrationsList = [];
				let brandsList = [];
				let campaignsList = [];
				this.form.allowed_funnel_families.forEach(ff => {
					this.funnel_families_relations[ff].funnel.forEach(e => {
						brandsList.push(e.brand.id)
						integrationsList.push(e.integration.id)
					});
					this.funnel_families_relations[ff].spreadsheet.forEach(e => {
						brandsList.push(e.brand.id)
						integrationsList.push(e.integration.id)
					});
					this.funnel_families_relations[ff].campaign.forEach(e => {
						campaignsList.push(e.uuid)
					});
				})
				this.form.allowed_brands = [...new Set(brandsList)];
				this.form.allowed_integrations = [...new Set(integrationsList)];
				this.form.allowed_campaigns = [...new Set(campaignsList)];
			},
			checkUsernameExists(e) {this._checkExists('username', e)},
			checkEmailExists(e) {this._checkExists('email', e)},
			async _checkExists(field, val) {
				try {
					let result = await this.$apix.sendHttpRequest('GET', 'user/exists', {field, val});
					if(result.exists) {
						this.errors[field] = { s: true, m: field + ' already exists' };
						this.existsError = true;
					} else {
						this.existsError = false;
					}
				} catch (e) {
					console.error(e);
				}
			}
		},

	}
</script>
